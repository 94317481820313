import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './Education.scss';

class Education extends React.Component {
  state = {
    education: [
      {
        title: 'Computer Science',
        year: '2016 - 2020',
        from: 'Universitas Muhammadiyah Surakarta',
        logo: require('../../images/logo-ums.png'),
        description: 'Bachelor of Computer Science with a concentration in information systems'
      },
      {
        title: 'High School',
        year: '2013 - 2016',
        from: 'SMK Ganesha Tama Boyolali',
        logo: require('../../images/ganesha.png')
      },
      {
        title: 'Junior High School',
        year: '2010 - 2013',
        from: 'SMP N 1 Teras Boyolali',
        logo: require('../../images/smp1teras.png')
      }
    ]
  }

  renderEducation = () => {
    let items = [];
    this.state.education.forEach((item, index) => {
      items.push(
        <Card key={index}>
          <Card.Body>
            <Row>
              <Col xs={4} md={2}>
                <img src={item.logo.default} alt="" className="w-100" />
              </Col>
              <Col xs={8} md={10}>
                <h4>{item.title}</h4>
                <h5>{item.year}</h5>
                <p><em>{item.from}</em></p>
                {item.description ? <p>{item.description}</p> : null}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )
    })
    return items;
  }

  render() {
    return (
      <section id="education">
        <Container>
          <Row>
            <Col lg={12} className="education" data-aos="fade-right">
              <h3 className="education-title">Education</h3>
              <div className="education-item">
                {this.renderEducation()}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

export default Education;