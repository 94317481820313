import React from 'react';
import { Container, Row, Col, ProgressBar } from 'react-bootstrap';
import './Skills.scss';

class Skills extends React.Component {
  state = {
    odd: [
      ['HTML & CSS', 95],
      ['Javascript', 80],
      ['SASS', 75],
      ['Vue Js', 70],
      ['React Js', 60],
      ['Node Js', 70],
    ],
    even: [
      ['Ruby', 50],
      ['Python', 50],
      ['PHP', 50],
      ['MySQL', 50],
      ['PostgreSQL', 70]
    ]
  }

  renderSkills = (array) => {
    let items = [];
    array.forEach((item) => {
      items.push(<div className="progress" key={item}>
        <span className="skill">{item[0]}<i className="val">{item[1]}%</i></span>
        <ProgressBar className="wrap" animated now={item[1]} max={100} />
      </div>)
    })
  return items
  }

  render() {
    return (
      <section id="skills">
        <Container>
          <div className="section-title">
            <h2>Skills</h2>
          </div>
          <Row className="skills-content">
            <Col lg={6} data-aos="fade-right">
              {this.renderSkills(this.state.odd)}
            </Col>
            <Col lg={6} data-aos="fade-left" data-aos-delay="100">
              {this.renderSkills(this.state.even)}
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

export default Skills;