import React from 'react';
import { Facebook, Instagram, Linkedin, PersonFill, Tools, JournalCode, Briefcase, Bank, X, List } from 'react-bootstrap-icons';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-scroll';
import './SideBar.scss';
import imageProfile from '../../images/profile.png';

class SideBar extends React.Component {
  state = {
    activeMobile: false
  };

  activeNav = () => {
    this.setState({
      activeMobile: !this.state.activeMobile
    })
  }

  render() {
    return (
      <header id="header" className={this.state.activeMobile ? 'mobile-active' : ''} >
        <div className="d-flex flex-column">
          <div className="profile">
            <img src={imageProfile} alt="" className="img-fluid rounded-circle" />
            <h5 className="text-light font-weight-bold">Nanda Yusuf Nur Pratama</h5>
            <h6 className="text-light text-center">Web Developer</h6>
            <div className="social-links mt-3 text-center">
              <a href="https://www.facebook.com/nanda.y.n.p" target="_blank" className="facebook" rel="noopener noreferrer"><Facebook /></a>
              <a href="https://www.instagram.com/nanda.pratama389" target="_blank" className="instagram" rel="noopener noreferrer"><Instagram /></a>
              <a href="https://www.linkedin.com/in/nanda-yusuf-nur-pratama-bb7529202" target="_blank" className="linkedin" rel="noopener noreferrer"><Linkedin /></a>
            </div>
          </div>
          <Nav className="flex-column">
            <Link to="about"spy activeClass="is-active"><PersonFill /><span>About</span></Link>
            <Link to="skills" spy activeClass="is-active"><Tools /><span>Skills</span></Link>
            <Link to="education" spy activeClass="is-active"><Bank /><span>Education</span></Link>
            <Link to="resume" spy activeClass="is-active"><Briefcase /><span>Work Experience</span></Link>
            <Link to="portfolio" spy activeClass="is-active"><JournalCode /><span>Portfolio</span></Link>
          </Nav>
          <button type="button" className="mobile-nav-toggle d-xl-none" onClick={this.activeNav}>{this.state.activeMobile ? <X className="text-white" /> : <List className="text-white" />}</button>
        </div>
      </header>
    )
  }
}

export default SideBar;