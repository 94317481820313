import { useEffect } from 'react';
import './App.scss';
import Intro from './components/Intro/Intro';
import SideBar from './components/SideBar/SideBar';
import Skills from './components/Skills/Skills';
import Education from './components/Education/Education';
import Work from './components/WorkExperience/WorkExperience';
import Portfolio from './components/Portfolio/Portfolio';
import Top from './components/ScrollToTop/ScrollToTop';
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    AOS.init({
      once: true
    });
  }, []);

  return (
    <div className="App">
      <SideBar />
      <div className="home">
        <Intro />
        <Skills />
        <Education />
        <Work />
        <Portfolio />
        <Top />
      </div>
    </div>
  );
}

export default App;
