import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './WorkExperience.scss';

class Work extends React.Component {
  state = {
    experience: [
      {
        position: 'Frontend Web & Bot developer',
        time: 'Jan 2021 - May 2021',
        company: 'ChatAja Messanger Indonesia',
        description: 'Work as Frontend Web Developer for maintain ChatAja Web-Based app and other, build bots with node js and ruby',
        logo: require('../../images/logo-chataja.png')
      },
      {
        position: 'Web & Bot Developer',
        time: 'Feb 2020 - Aug 2020',
        company: 'PT Telekomunikasi Indonesia',
        description: 'Intern as a web developer to create a website for clients that functions to post on channels and official accounts on the Chataja application, create a chatbot with node js and perform data analysis',
        logo: require('../../images/telkom.png')
      },
      {
        position: 'Web Developer',
        time: 'Mar 2019 - May 2019',
        company: 'SMP Negeri 2 Teras',
        description: 'Intern as a web developer using PHP to create a website-based application that has the function of being an e-report system for this school',
        logo: require('../../images/logo-boyolali.png')
      }
    ]
  }

  renderExperience = () => {
    let items = [];
    this.state.experience.forEach((item, index) => {
      items.push(
        <Card key={index}>
        <Card.Body>
          <Row>
            <Col xs={4} md={2}>
              <img src={item.logo.default} alt="" className="w-100" />
            </Col>
            <Col xs={8} md={10}>
              <h4>{item.position}</h4>
              <h5>{item.time}</h5>
              <p><em>{item.company}</em></p>
              <p>{item.description}</p>
            </Col>
          </Row>
        </Card.Body>
        </Card>
      )
    })
    return items;
  }

  render() {
    return (
      <section id="resume">
        <Container>
          <Row>
            <Col lg={12} className="resume" data-aos="fade-right">
              <h3 className="resume-title">Work Experience</h3>
              <div className="resume-item">
                {this.renderExperience()}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

export default Work;