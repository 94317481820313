import React from 'react';
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';
import './Portfolio.scss';

class Portfolio extends React.Component {
  state = {
    showModal: false,
    modalActiveData: {
      title: '',
      image: '',
      created: '',
      description: '',
      finished: ['']
    },
    modalData: [
      {
        title: 'ChatAja News',
        image: require('../../images/CA-news.png'),
        created: 'Vue Js',
        description: 'Build article page and publisher page and Maintain ChatAja News Web',
        link: 'https://news.chataja.co.id',
        finished: ['Article Page', 'Publisher Page', 'ADS']
      },
      {
        title: 'Analytic ChatAja News',
        image: require('../../images/analityc-news.png'),
        created: 'Vue Js',
        description: 'Build web to display web analytics of ChatAja News',
        finished: ['Page View per page', 'Page View per category and publisher', 'Share article per category and publisher', 'User Retention']
      },
      {
        title: 'ChatAja Web App',
        image: require('../../images/chataja-web.png'),
        created: 'Vue Js',
        description: 'Maintain ChatAja Web App',
        link: 'https://web.chataja.co.id',
        finished: ['Bug fixing', 'Implement Sticker']
      },
      {
        title: 'Redesign Company Profile ChatAja',
        image: require('../../images/chataja.png'),
        created: 'Ruby On Rails',
        description: 'Redesign Company Profile ChatAja With Ruby On Rails and Bootstrap 5',
        link: 'https://chataja.co.id',
        finished: [
          'Home Page',
          'Blog Page',
          'Release Note Page',
          'Feature Page',
          'Download Page',
          'Blog Page',
          'Success Story Page',
          'Bot Page',
          'About Page'
        ]
      },
      {
        title: 'Berdi & Tatan',
        image: require('../../images/berdi-tatan.png'),
        created: 'Ruby On Rails',
        description: 'Build video interactive web for berdi-tatan series on ChatAja with Ruby On Rails, Video Js and Bootstrap 5',
        link: 'https://chataja.co.id/berdi-tatan',
        finished: ['Video Interactive page', 'Dashboard Page']
      },
      {
        title: 'Dashboard ChatAja Ramadhan',
        image: require('../../images/leaderbord-alim.png'),
        created: 'Ruby On Rails',
        description: 'Build web to inform user about leaderboard quiz of ChatAja Ramadan bot and dashboard used by the admin to manage content on the ChatAja Ramadan bot',
        link: 'http://alim.chataja.co.id',
        finished: ['Leaderboard Quiz', 'One Day One Ayat Page', 'Quiz Page', 'Deposit Tadarus Page']
      },
      {
        title: 'ChatAja Ramadhan Bot',
        image: require('../../images/chatbot-alim.png'),
        created: 'Node Js',
        description: 'Build chatbot for Ramadan promotional media of Chataja',
        finished: ['Deposit Tadarus', 'Adzan Checker', 'One Day One Ayat', 'Quiz Ramadhan', 'Reminder imsak and buka puasa']
      },
      {
        title: 'E-Rapor SMP N 2 Teras',
        image: require('../../images/e-rapor-SMP2.png'),
        created: 'PHP Native',
        description: 'Build web based application for E-Rapor SMP N 2 Teras',
        finished: [
          'Login Page',
          'Page Data Sekolah',
          'Page Guru',
          'Page Mata Pelajaran',
          'Page Daftar SIswa',
          'Page Kelas',
          'Page Indikator Penilaian',
          'Page Kompetensi Dasar',
          'Page Rencana Penilaian',
          'Page Input Nilai',
          'Cetak Rapor',
          'Page Prestasi Siswa',
          'Page Ketidakhadiran Siswa'
        ]
      }
    ]
  }

  showModal = (id) => {
    const data = this.state.modalData[id]
    this.setState({
      showModal: true,
      modalActiveData: data
    })
  }

  closeModal = () => {
    this.setState({
      showModal: false
    })
  }

  renderFInished(data) {
    let listData = [];
    data.forEach((item, index) => {
      listData.push(<li key={index}>{item}</li>)
    })
    return listData;
  }

  renderModal = () => {
    const modalData = this.state.modalActiveData;
    return (
      <Modal size="lg" show={this.state.showModal} onHide={this.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">{modalData.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="mb-5 mx-auto">
            <Card.Img variant="top" src={modalData.image.default} />
          </Card>
          <div className="mb-4 text-dark">
            <p>
              <b>Created With: {modalData.created}</b>
              {modalData.link ? <b> - <a href={modalData.link} target="_blank" rel="noopener noreferrer">{modalData.link}</a></b> : null}
            </p>
            <p>{modalData.description}</p>
            <p>What i have finished:</p>
            <ol>
              {this.renderFInished(modalData.finished)}
            </ol>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="mt-3 w-25 mx-auto" variant="secondary" onClick={this.closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  renderPortfolio = () => {
    let items = [];
    this.state.modalData.forEach((item, index) => {
      items.push(
        <Col lg={12} className="portfolio-item" data-aos="zoom-in" data-aos-delay="100" key={index}>
          <Card className="mx-auto mb-5">
            <div className="portfolio-wrap">
              <Card.Img variant="top" src={item.image.default} />
              <div className="d-flex flex-column portfolio-links" onClick={() => {this.showModal(index)}}>
                <span className="h5 font-weight-bold text-center">{item.title}</span>
                <div className="btn btn-info rounded-pill mx-auto">{item.created}</div>
              </div>
            </div>
          </Card>
        </Col>
      )
    })
    return items;
  }

  render() {
    return (
      <section id="portfolio">
        <Container>
          <div className="section-title">
            <h2>Portfolio</h2>
          </div>
          <Row className="portfolio-container">
              {this.renderPortfolio()}
          </Row>
        </Container>

        {this.renderModal()}
      </section>
    )
  }
}

export default Portfolio;