import { Container, Row, Col } from 'react-bootstrap';
import { GeoAlt, Envelope } from 'react-bootstrap-icons';
import imageProfile from '../../images/profile.png';
import './Intro.scss';

const calculateAge = (dob) => {
  const diffMs = Date.now() - dob.getTime()
  const ageDt = new Date(diffMs)
  return Math.abs(ageDt.getUTCFullYear() - 1970)
}

const Intro = () => {
  return (
    <section id="about">
      <Container>
        <div className="section-title">
          <h2>About</h2>
        </div>
        <Row>
          <Col lg={4} data-aos="fade-right">
            <img src={imageProfile} alt="" className="img-fluid d-none d-lg-block" />
            <img src={imageProfile} className="img-fluid d-block d-lg-none w-50 mx-auto" alt="" />
          </Col>
          <Col lg={6} className="pt-4 pt-lg-0 content ml-lg-5" data-aos="fade-left">
            <p className="mb-lg-5">Hello i am nanda, i am a person who likes to learn new things, my specialty is in web development. The programming languages that I offen use is Vue Js, React Js, Node Js, Ruby, Python, PHP. I am an adaptable person, good at communicating and good at working in teams.</p>
            <Row>
              <Col lg={12}>
                <ul>
                  <li>{calculateAge(new Date(1999, 7, 3))} <strong>years</strong></li>
                  <li><GeoAlt /> Boyolali, Central Java</li>
                  <li><Envelope /> nandayusuf58@gmail.com</li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
};

export default Intro;