import React from 'react';
import { Button } from "react-bootstrap";
import { ArrowUp } from "react-bootstrap-icons";
import './ScrollToTop.scss';

class Top extends React.Component {
  ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  CheckScrollPos = (data) => {
    const scrollToTopBtn = document.querySelector('.scroll-to-top')
    if (data > 100) {
      // show button
      if (!scrollToTopBtn.classList.contains('showScrollBtn')) {
        scrollToTopBtn.classList.add('showScrollBtn')
      }
    } else {
      // hide button
      if (scrollToTopBtn.classList.contains('showScrollBtn')) {
        scrollToTopBtn.classList.remove('showScrollBtn')
      }
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      var prevScrollPos = window.pageYOffset
      this.CheckScrollPos(prevScrollPos)
    })
  }

  render() {
    return (
      <Button className="scroll-to-top rounded-pill px-2" onClick={this.ScrollToTop}><ArrowUp /></Button>
    )
  }
}

export default Top;